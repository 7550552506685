import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import { Section, Container, Content } from "@atoms/layout"
import Layout from "@utils/layout"

const TOSPage = ({ data }) => {
  return (
    <Layout>
      <HelmetDatoCms seo={data.datoCmsTermsOfService.seoMetaTags}/>
      <Section lgTop xl data-datocms-noindex>
        <Container containerLarge>
          <Content
            dangerouslySetInnerHTML={{
              __html: data.datoCmsTermsOfService.content,
            }}
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default TOSPage

export const fetchTerms = graphql`
  query fetchTerms {
    datoCmsTermsOfService {
      id
      content
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
